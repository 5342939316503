import React, { useEffect, useState } from 'react';
import { Container, Link, Typography, Box, Button, List, ListItem, ListItemText, Grid, Paper, AppBar, Toolbar, IconButton, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import setAuthToken, { getConferenceRooms, getBookings, createBooking, deleteBooking,getFeeStandard } from './api';
import Logo from './logo.png';
import './ConferenceRoomList.css'; // 引用同目录下的 CSS 文件
import 'moment/locale/zh-cn';  // 引入中文本地化
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Collapse from '@mui/material/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PasswordChangeComponent from './PasswordChangeComponent';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'; // 替换为适合展示收费标准的图标

moment.locale('zh-cn');        // 设置全局语言为中文

const localizer = momentLocalizer(moment);

const ErrorDialog = ({ open, onClose, errorMessage }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>注意</DialogTitle>
      <DialogContent>
        <Typography color="error">{errorMessage}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">关闭</Button>
      </DialogActions>
    </Dialog>
  );
};

const ConferenceRoomList = ({ onLogout }) => {
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [error, setError] = useState('');
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [newBooking, setNewBooking] = useState({ title: '新会议', start: null, end: null });
  const [view, setView] = useState('week');  // 默认设置为月视图
  const views = ['month', 'week', 'day'];  // 定义允许的视图类型，去除 'agenda'
  const messages = {
    today: '回到现在',
    previous: '上一页',
    next: '下一页',
    month: '月视图',
    week: '周视图',
    day: '日视图',
    showMore: total => `另有${total}场会议`
  };

  const eventPropGetter = (event) => {
    const style = {
      backgroundColor: event.isInternal === 1 ? '#a5bbfb' : '#fbbaa5',
    };
    return { style };
  };

  const [expandedRoomId, setExpandedRoomId] = useState(null);

  const handleRoomClick = (roomId) => {
    setExpandedRoomId(expandedRoomId === roomId ? null : roomId);
  };

  const minTime = new Date();
  minTime.setHours(7, 0, 0); // 设置最小时间为早上7点

  const maxTime = new Date();
  maxTime.setHours(22, 0, 0); // 设置最大时间为晚上10点

  const showErrorDialog = (errorMessage) => {
    setError(errorMessage);
    setErrorDialogOpen(true);
  };

  // 修改密码
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const [feeStandardOpen, setFeeStandardOpen] = useState(false);
  const [feeStandard, setFeeStandard] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    if (!token || !user) {
      window.location.href = '/login'; // 用户未登录，跳转到登录页面
      return;
    }

    setAuthToken(token); // 设置 token
    const fetchRooms = async () => {
      try {
        const response = await getConferenceRooms();
        setRooms(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // 清除本地存储的token和user信息
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          // 重定向到登录页面
          window.location.href = '/login';
        } else {
          showErrorDialog('加载会议室列表失败');
        }
      }
    };

    fetchRooms();
  }, []);

  const handleRoomSelect = (room) => {
    setSelectedRoom(room);
    setExpandedRoomId(expandedRoomId === room.id ? null : room.id);
    fetchEvents(room.id); // 获取会议室预定情况
  };

  const fetchEvents = async (roomId) => {
    try {
      const response = await getBookings(roomId);
      const bookings = response.data.map(booking => ({
        title: `${booking.company_name}`,
        id: booking.id,
        start: new Date(`${booking.date}T${booking.start_time}`),
        end: new Date(`${booking.date}T${booking.end_time}`),
        realtitle: booking.title,
        company: booking.company_name,
        bookedBy: booking.full_name,
        isInternal: booking.is_internal  // 获取企业类型
      }));
      setEvents(bookings);
    } catch (error) {
      showErrorDialog('加载预定信息失败');
    }
  };
  

  //增加对于园区外部的企业的收费提示
  const [feeWarningOpen, setFeeWarningOpen] = useState(false);
  const [pendingBooking, setPendingBooking] = useState(null); // 用于存储待确认的预定信息
  
  const handleOpenFeeWarning = () => {
    setFeeWarningOpen(true);
  };
  
  const handleCloseFeeWarning = () => {
    setFeeWarningOpen(false);
    setPendingBooking(null); // 清除待确认的预定信息
  };
  
  const handleConfirmFeeWarning = async () => {
    setFeeWarningOpen(false);
    if (pendingBooking) {
      await proceedWithBooking(pendingBooking);
      setPendingBooking(null); // 清除待确认的预定信息
    }
  };
  

  const handleSelectSlot = ({ start, end }) => {
    setError('');
  
    if (!selectedRoom) {
      showErrorDialog('请先选择一个会议室。');
      return;
    }
  
    const duration = moment(end).diff(moment(start), 'hours', true);
  
    if (duration > 2) {
      showErrorDialog('选择的时间段不能超过2小时。');
      return;
    }
  
    setNewBooking({ title: '新会议', start, end });
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const proceedWithBooking = async (bookingData) => {
    try {
      await createBooking(bookingData); // Replace this with your actual API call
      fetchEvents(selectedRoom.id);
      handleClose();
      setNewBooking({ title: '新会议', start: null, end: null });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        showErrorDialog(error.response.data.detail);
      } else {
        console.error('Failed to create booking:', error);
        showErrorDialog('发生错误，请稍后重试');
      }
    }
  };
  
  const handleBooking = async () => {
    if (!newBooking.title) return;
    const user = JSON.parse(localStorage.getItem('user'));
    const bookingData = {
      room_id: selectedRoom.id,
      date: moment(newBooking.start).format('YYYY-MM-DD'),
      start_time: moment(newBooking.start).format('HH:mm:ss'),
      end_time: moment(newBooking.end).format('HH:mm:ss'),
      title: newBooking.title,
      booked_by_user_id: user.id,
    };
  
    if (user.is_internal === 2) { // 检查是否为园区外企业
      setPendingBooking(bookingData);
      handleOpenFeeWarning();
      return;
    }
  
    await proceedWithBooking(bookingData);
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setDetailsOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsOpen(false);
  };

  const handleDeleteBookingWithConfirmation = () => {
    if (window.confirm("确定要取消这个预定吗？")) {
      handleDeleteBooking();
    }
  };

  const handleDeleteBooking = async () => {
    if (!selectedEvent || !selectedEvent.id) {
      console.error("No event selected or missing event ID");
      return;
    }

    try {
      const response = await deleteBooking({ id: selectedEvent.id });
      if (response.status === 201) { // 确保这里匹配后端返回的成功状态码
        // console.log("删除成功");
        setDetailsOpen(false); // 关闭详细信息对话框
        fetchEvents(selectedRoom.id); // 重新获取当前会议室的预定情况来更新UI
      } else {
        console.error("Failed to delete booking:", response.data);
        showErrorDialog('Failed to delete booking.');
      }
    } catch (error) {
      console.error('Error deleting booking:', error);
      showErrorDialog('Error deleting booking.');
    }
  };
  const handleFeeStandardClick = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    try {
      const response = await getFeeStandard(user.company_id);
      setFeeStandard(response.data.fee_standard);
      setFeeStandardOpen(true);
    } catch (error) {
      console.log(user,"666",user.company_id)
      showErrorDialog('加载收费标准失败');
    }
  };
  
  const handleCloseFeeStandard = () => {
    setFeeStandardOpen(false);
  };
  
  const FeeStandardDialog = ({ open, onClose, feeStandard }) => (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>收费标准</DialogTitle>
      <DialogContent>
        {feeStandard ? (
          <img src={`data:image/jpeg;base64,${feeStandard}`} alt="收费标准" style={{ width: '100%' }} />
        ) : (
          <Typography>收费标准未上传</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">关闭</Button>
      </DialogActions>
    </Dialog>
  );
  const FeeWarningDialog = ({ open, onClose, onConfirm }) => (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>注意</DialogTitle>
      <DialogContent>
        <Typography>会议室预定需收费，收费标准请参考收费表。</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">取消</Button>
        <Button onClick={onConfirm} color="primary">确定</Button>
      </DialogActions>
    </Dialog>
  );
  

  
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container spacing={0} style={{ height: '100%' }}>
        <Grid item xs={12} md={3} style={{ height: '100%' }}>
          <Paper sx={{ p: 4, height: '100%' }}>
            <img src={Logo} alt="Logo" style={{ height: 100 }} />
            <List>
              {rooms.map((room) => (
                <React.Fragment key={room.id}>
                  <ListItem
                    button
                    onClick={() => handleRoomSelect(room)}
                    selected={selectedRoom && room.id === selectedRoom.id}
                    sx={{
                      backgroundColor: '#fff',
                      transition: 'background-color 300ms ease-in-out, box-shadow 300ms ease-in-out',
                      '&:hover': {
                        backgroundColor: '#c6d2f6',
                        boxShadow: '1px 4px 8px #d9e1f9'
                      },
                      '&.Mui-selected, &.Mui-selected:hover': {
                        backgroundColor: '#6787e7',
                        color: '#fff',
                        boxShadow: ' 1px 4px 8px 1px #a0b4f0'
                      },
                      width: '100%',
                      justifyContent: 'space-between',
                      borderRadius: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    <ListItemText primary={room.name} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={() => handleRoomSelect(room)}>
                        {expandedRoomId === room.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Collapse in={expandedRoomId === room.id} timeout="auto" unmountOnExit>
                    <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', borderRadius: '10px', marginBottom: '10px' }}>
                      <Typography variant="body1"> {room.message}</Typography>
                      {room.photo && <img src={`data:image/png;base64,${room.photo}`} alt={`${room.name}的照片`} style={{ width: '100%', marginTop: '10px' }} />}
                    </Box>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>

            <Box
                sx={{
                    position: 'fixed',
                    bottom: '20px',
                    left: '35px',
                    backgroundColor: 'white',
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    zIndex: 1000,
                    fontSize: '12px', // 设置字号变小
                    color: 'gray', // 设置成灰色
                    lineHeight: '1.5', // 行高，确保两行之间有适当的间距
                }}
            >
                <div>感谢使用台州国际创新中心（杭州）会议室预定系统。</div>
                <div>该系统智能机器人由{' '}
                    <Link href="https://www.asktable.com/" target="_blank" rel="noopener noreferrer" sx={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>
                        AskTable
                    </Link>{' '}
                    提供技术支持。
                </div>
            </Box>

          </Paper>
        </Grid>

        <Grid item xs={12} md={9}>
        <AppBar position="static" sx={{ minHeight: '80px', padding: '35px 10px 30px', backgroundColor: ' #6787e7' }}>
          <Toolbar>
            <Typography variant="h5" sx={{ flexGrow: 1, fontWeight: 'bold', fontSize: '2rem' }}>
              会议室预定系统
              <IconButton
                color="inherit"
                onClick={handleFeeStandardClick}
                title="收费标准"
                sx={{marginBottom:0.5,marginLeft:1, width: 48, height: 48, '& .MuiSvgIcon-root': { fontSize: '2.5rem' } }}
              >
                <MonetizationOnIcon />
              </IconButton>
              收费标准
              <FeeStandardDialog open={feeStandardOpen} onClose={handleCloseFeeStandard} feeStandard={feeStandard} />
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 0.2, marginRight: 0.5, fontSize: '1.5rem' }}>
              {`${JSON.parse(localStorage.getItem('user')).full_name || '待设置'} - ${JSON.parse(localStorage.getItem('user')).company_name || '待设置'}`}
            </Typography>
            <IconButton
              color="inherit"
              onClick={handleOpenDialog}
              title="修改密码"
              sx={{ width: 48, height: 48, '& .MuiSvgIcon-root': { fontSize: '2rem' } }}
            >
              <LockIcon />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={onLogout}
              title="退出登录"
              sx={{ width: 48, height: 48, '& .MuiSvgIcon-root': { fontSize: '2rem' } }}
            >
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

          <PasswordChangeComponent open={isDialogOpen} handleClose={handleCloseDialog} />

          <Paper sx={{ p: 2, height: '100%', padding: '35px ' }}>
            {selectedRoom ? (
              <div>
                <Typography variant="h5" gutterBottom sx={{ marginBottom: '15px ', color: '#213571', fontWeight: '550' }}>
                  {selectedRoom.name}的预定情况
                </Typography>
                <Calendar
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 680 }}
                  selectable={view === 'week' || view === 'day'}
                  onView={newView => setView(newView)}
                  view={view}
                  onSelectSlot={handleSelectSlot}
                  onSelectEvent={handleSelectEvent}
                  views={views}
                  messages={messages}
                  min={minTime}
                  max={maxTime}
                  step={30}
                  timeslots={2}
                  popup={true}
                  eventPropGetter={eventPropGetter}  // 新增属性
                />
              </div>
            ) : (
              <Typography variant="h6" align="center">
                请选择一个会议室以查看预定情况
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ marginTop: 1, marginBottom: -2, fontWeight: 'bold', color: '#213571' }}>{`${JSON.parse(localStorage.getItem('user')).company_name} - 预定会议室`}</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            {newBooking.start ? moment(newBooking.start).format('HH:mm:ss') : '未选择'} - {newBooking.end ? moment(newBooking.end).format('HH:mm:ss') : '未选择'}
          </Typography>
          <TextField sx={{ marginTop: 1, marginBottom: -2 }}
            autoFocus
            margin="dense"
            label="议题"
            fullWidth
            value={newBooking.title}
            onChange={(e) => setNewBooking({ ...newBooking, title: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          <Button onClick={handleBooking}>确认</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={detailsOpen} onClose={handleCloseDetails}>
        <DialogTitle sx={{ marginTop: 1.5, marginBottom: 0, fontWeight: 'bold', color: '#213571', fontSize: '1.7rem' }}>{selectedEvent ? `${selectedEvent.company}` : ''}</DialogTitle>
        <DialogContent sx={{ marginBottom: -2 }}>
          {selectedEvent && (
            // console.log(localStorage.getItem('user')),
            // console.log(JSON.parse(localStorage.getItem('user'))?.company_name, selectedEvent.company),
            <div>
              <Typography>开始时间: {selectedEvent.start.toLocaleString()}</Typography>
              <Typography>结束时间: {selectedEvent.end.toLocaleString()}</Typography>
              <Typography>会议的议题: {selectedEvent.realtitle}</Typography>
              <Typography>预定的公司: {selectedEvent.company}</Typography>
              <Typography>预定的用户: {selectedEvent.bookedBy}</Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: 1.5 }}>
          {selectedEvent && JSON.parse(localStorage.getItem('user'))?.company_name === selectedEvent.company && (
            <Button onClick={handleDeleteBookingWithConfirmation} color="secondary">
              取消预定
            </Button>
          )}
          <Button onClick={handleCloseDetails}>关闭</Button>
        </DialogActions>
      </Dialog>

      <ErrorDialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)} errorMessage={error} />
      <FeeStandardDialog open={feeStandardOpen} onClose={handleCloseFeeStandard} feeStandard={feeStandard} />
      <FeeWarningDialog open={feeWarningOpen} onClose={handleCloseFeeWarning} onConfirm={handleConfirmFeeWarning}/>

    </React.Fragment>
  );
};

export default ConferenceRoomList;
