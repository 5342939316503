import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, DialogActions, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';

const PasswordChangeComponent = ({ open, handleClose }) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const ErrorDialog = ({ open, onClose, errorMessage }) => {
        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>注意</DialogTitle>
                <DialogContent>
                    <Typography color="error">{errorMessage}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">关闭</Button>
                </DialogActions>
            </Dialog>
        );
    };

    const showErrorDialog = (errorMessage) => {
        setError(errorMessage);
        setErrorDialogOpen(true);
    };

    const clearDialogContents = () => {
        setOldPassword('');
        setNewPassword('');
        setMessage('');
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();

        if (!oldPassword || !newPassword) {
            showErrorDialog('请填写完整信息');
            return;
        }

        try {
            const response = await axios.put('http://localhost:8000/api/change-password/', {
                old_password: oldPassword,
                new_password: newPassword,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.data.detail === 'Password has been changed.') {
                showErrorDialog('修改密码成功');
                setTimeout(() => {
                    clearDialogContents(); // 清空对话框内容
                    handleClose();  // 关闭对话框
                }, 2000);  // 延迟2秒关闭对话框，用户可以看到提示信息
            } else {
                showErrorDialog('旧密码输入错误');
            }
        } catch (error) {
            if (error.response && error.response.data) {
                showErrorDialog(error.response.data.old_password || error.response.data.new_password || '旧密码输入错误');
            } else {
                showErrorDialog('An error occurred');
            }
        }
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ fontSize: '1.5rem',marginTop: 1, marginBottom: 0, fontWeight: 'bold', color: '#213571' }}>修改密码</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleChangePassword}>
                        <div>
                            <TextField
                                sx={{ marginTop: 1, marginBottom: 1 }}
                                autoFocus
                                margin="dense"
                                label="旧密码"
                                fullWidth
                                type="password"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <TextField
                                sx={{ marginTop: 0.5, marginBottom: -2 }}
                                margin="dense"
                                label="新密码"
                                fullWidth
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </div>
                    </form>
                    {message && <Typography color="success">{message}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button type="submit" onClick={handleChangePassword}>确认修改</Button>
                </DialogActions>
            </Dialog>
            <ErrorDialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)} errorMessage={error} />
        </>
    );
};

export default PasswordChangeComponent;
