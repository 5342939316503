import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, TextField, Button, Paper, Grid } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';
import { login } from './api';
import Logo from './logo.png';

const Login = ({ setToken, setUser }) => {
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [redirectToConferenceRooms, setRedirectToConferenceRooms] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const demoUser = searchParams.get('demo_user');
    const demoPwd = searchParams.get('demo_pwd');

    if (demoUser) {
      setUsername(demoUser);
    }
    if (demoPwd) {
      setPassword(demoPwd);
    }
  }, [location.search]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(username, password);
      const token = response.data.access;
      const user = response.data.user;
      setToken(token);
      setUser(user);
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      setRedirectToConferenceRooms(true);
    } catch (error) {
      setError('用户名或密码错误');
    }
  };

  if (redirectToConferenceRooms) {
    return <Navigate to="/conference-rooms" />;
  }

  return (
    <Container maxWidth="xs" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper elevation={3} sx={{
        width: '100%', maxWidth: 400, p: 4, borderRadius: 10,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
        display: 'flex', flexDirection: 'column', alignItems: 'center',
        backgroundColor: '#f8f8f8', color: '#333'
      }}>
        <Box mb={0}>
            <img src={Logo} alt="Logo" style={{ height: 100 }} />
        </Box>
        <Grid container direction="column" alignItems="center">
          {error && (
            <Typography color="error" align="center" sx={{ mt: 1, mb: 2 }}>
              {error}
            </Typography>
          )}
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <TextField
              label="用户名"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="密码"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Box mt={2}>
              <Button type="submit" variant="contained" color="primary" fullWidth sx={{
                backgroundColor: '#6495ED', '&:hover': { backgroundColor: '#4169E1' },
                borderRadius: 10, boxShadow: 'none', textTransform: 'none', fontWeight: 500
              }}>
                登录
              </Button>
            </Box>
          </form>
        </Grid>
      </Paper>

      <Box
        sx={{
          position: 'fixed',
          bottom: '25px',
          right: '100px',
          zIndex: 1000,
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => window.location.href = 'https://api.meeting-demo.asktable.com/admin'}
          sx={{
            borderRadius: 10,
            marginBottom: 5,
            backgroundColor: '#6495ED', '&:hover': { backgroundColor: '#4169E1' },
          }}
        >
          管理员入口
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
