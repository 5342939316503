import axios from 'axios';

// 基础 API URL 的设置，兼容浏览器环境和Node环境
const API_BASE_URL = (typeof window !== 'undefined' && window.env?.REACT_APP_API_BASE_URL) || process.env.REACT_APP_API_BASE_URL;

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 设置或删除认证Token
const setAuthToken = (token) => {
  if (token) {
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete apiClient.defaults.headers.common['Authorization'];
  }
};

// 登录 API，获取token
export const login = (username, password) => {
  return apiClient.post('/api/login/', { username, password });
};

// 获取会议室列表
export const getConferenceRooms = () => {
  return apiClient.get('/api/conference_rooms/');
};

// 根据会议室ID获取预定情况
export const getBookings = (roomId) => {
  return apiClient.get('/api/bookings/', {
    params: { room_id: roomId },
  });
};

// 创建新的预定
export const createBooking = (bookingData) => {
  return apiClient.post('/api/bookings/create/', bookingData);
};
// 删除预定
export const deleteBooking = (idData) => {
  return apiClient.post(`/api/bookings/delete/`, idData)
};

export const getFeeStandard = async (company_id) => {
  return apiClient.get(`/api/fee-standard/${company_id}/`);
};


export default setAuthToken;